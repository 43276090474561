import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostBox from "../components/post-box";
import Categories from "../components/blog/categories";
import Pagination from "../components/pagination";
import Image from "gatsby-image";


const BlogIndex = ({
  location,
  data,
  pageContext: {currentPage, totalPages},
}) => {
  const title = "Blog - Derave Software";
  const description = "We help you get better in IT: case studies, insights, guidelines and success stories. Stay informed about the software trends.";

  const featuredPost = data.allWpPost.nodes[0];
  const featuredAuthor = featuredPost.author.node.firstName + ' ' + featuredPost.author.node.lastName;
  const featuredUsername = featuredPost.author.node.slug;

  let posts = data.allWpPost.nodes;

  if (currentPage === 1) {
    posts = posts.slice(1);
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
      />

      {currentPage === 1 && <section className="article-featured">
        <div className="container-fluid">
          <div className="row columns-8">
            <h1 className="article-featured__blog_title">What we write</h1>

            <Link to={featuredPost.uri} className="article-featured__image">
              <Image
                fluid={featuredPost.featuredImage.node.localFile.childImageSharp.fluid}
                alt={featuredPost.title}
              />
            </Link>

            <div className="article-featured__container">
              <Categories terms={featuredPost.terms.nodes}/>

              <h2 className="article-featured__title"><Link to={featuredPost.uri}>{parse(featuredPost.title)}</Link></h2>

              <span className="article-featured__author">
                  <span className="article-featured__prefix">By</span>&nbsp;
                 <Link to={'/author/' + featuredUsername}>{featuredAuthor}</Link>
              </span>

              <div className="article-featured__description">
                {parse(featuredPost.excerpt)}
              </div>

              <div className="article-featured__readmore">
                <a href={featuredPost.uri}>Read more</a>
              </div>
            </div>


          </div>
        </div>
      </section>}

      {posts.length >= 1 && <section className="article-index-header">
        <div className="container-fluid">
          <div className="row columns-8">

            <span className="article-index-header__menu">
                <span className="article-index-header__sort">{currentPage === 1 ? 'other posts' : 'latest posts'}</span>
            </span>

            <div className="article-index-header__bg"/>
          </div>
        </div>
      </section>}

      {posts.length >= 1 && <section className="article-index">
        <div className="container-fluid">

          <div className="row article-index__row">
            {posts.map(post => {
              return <PostBox key={post.slug} post={post} />
            })}
          </div>

          <div className="row columns-4">
            <Pagination currentPage={currentPage} pageCount={totalPages} base={'/blog'}/>
          </div>
        </div>
      </section>}

    </Layout>
  )
};

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        slug
        
        post_acfs {
          ctaLink
          ctaText
        }
        
        author {
          node {
            firstName
            lastName
            slug
          }
        }
        
        terms {
          nodes {
            name
            slug
          }
        }
        
        featuredImage {
          node {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                fluid(maxWidth: 650, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        
      }
    }
  }
`
