import React from "react";
import Link from "gatsby-link";
import * as PropTypes from "prop-types";

class Pagination extends React.Component {
  isActive(i) {
    let {currentPage} = this.props;

    if (currentPage === (i + 1)) {
      return 'pagination-container__link active';
    } else {
      return 'pagination-container__link';
    }
  }

  getLink(i) {
    let {base} = this.props;

    if (i === 0) {
      return `${base}`;
    } else {
      return `${base}/page/${i + 1}`;
    }
  }

  render() {
    let {currentPage, pageCount, base} = this.props;
    return (
      <div className="pagination-container">

        {currentPage !== 1 && <span key={'prev'} className="pagination-container__link">
            <Link key={'prev'} to={`${base}`}>&laquo;</Link>
      </span>}

        {[...Array(pageCount)].map((e, i) => {
          return (<span key={`num${i}`} className={this.isActive(i)}>
        <Link key={`num${i}`} to={this.getLink(i)}>{i + 1}</Link>
      </span>)
        })}

        {currentPage !== pageCount && <span key={'next'} className="pagination-container__link">
        <Link key={'next'} to={`${base}/page/${currentPage + 1}`}>&raquo;</Link>
      </span>}

      </div>
    )
  }
}

Pagination.propTypes = {
  currentPage: PropTypes.any,
  pageCount: PropTypes.any,
  base: PropTypes.any
}

export default Pagination